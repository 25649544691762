export const INTEREST_TYPES = {
	FIX: 'fix',
	VARIABLE: 'változó'
};
export const LEASING_TYPES = {
	CLOSE: '2',
	OPEN: '3'
};
export const CALCULATOR_TYPES = {
	IT_AND_OFFICE: 'IT- és irodatechnikai eszközlízing',
	SUZUKI: 'Suzuki hajómotor lízing',
	MEDICAL: 'orvosi műszer'
};
export const VAT = 0.27;
