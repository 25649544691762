const SET_REST_AMOUNT_TEXT = 'SET_REST_AMOUNT_TEXT';
const SET_INSTALMENT = 'SET_INSTALMENT';
const SET_PRECALC_INSTALMENT = 'SET_PRECALC_INSTALMENT';
const SET_FINANCED_PRICE = 'SET_FINANCED_PRICE';
const SET_INTEREST_TYPE = 'SET_INTEREST_TYPE';
const SET_DURATION_TEXT = 'SET_DURATION_TEXT';
const SET_DURATION = 'SET_DURATION';
const SET_LEASING_TYPE = 'SET_LEASING_TYPE';
const SET_LEASING_TITLE = 'SET_LEASING_TITLE';
const SET_CALCULATOR_TYPE = 'SET_CALCULATOR_TYPE';
const SET_RNOTE = 'SET_RNOTE';
const SET_RAPPL_ID = 'SET_RAPPL_ID';
const SET_EMAIL = 'SET_EMAIL';
const SET_DEBTOR_TYPE = 'SET_DEBTOR_TYPE';
const SET_FINANCE_TYPE = 'SET_FINANCE_TYPE';
import { LEASING_TYPES } from '../production-tool-calculator/constants';
export default {
	namespaced: true,
	state: {
		restAmountText: null,
		instalment: null,
		precalcInstalment: null,
		financedPrice: null,
		interestType: null,
		durationText: null,
		leasingType: null,
		leasingTitle: null,
		calculatorType: null,
		rapplId: null,
		rnote: null,
		email: null,
		debtorType: null,
		duration: null,
		financeType: null
	},
	getters: {
		calculationEmailtext(state) {
			let text = state.leasingType == LEASING_TYPES.OPEN ? 'Nyiltvégű ' : 'Zártvégű ';
			text += state.calculatorType + ' ' + state.interestType + ' kamatozással';
			return text;
		}
	},
	actions: {
		setDuration({ commit }, duration) {
			commit(SET_DURATION, duration);
		},
		setRestAmountText({ commit }, restAmountText) {
			commit(SET_REST_AMOUNT_TEXT, restAmountText);
		},
		setInstalment({ commit }, instalment) {
			commit(SET_INSTALMENT, instalment);
		},
		setPrecalcInstalment({ commit }, precalcInstalment) {
			commit(SET_PRECALC_INSTALMENT, precalcInstalment);
		},
		setFinancedPrice({ commit }, financedPrice) {
			commit(SET_FINANCED_PRICE, financedPrice);
		},
		setInterestType({ commit }, interestType) {
			commit(SET_INTEREST_TYPE, interestType);
		},
		setDurationText({ commit }, durationText) {
			commit(SET_DURATION_TEXT, durationText);
		},
		setLeasingType({ commit }, leasingType) {
			commit(SET_LEASING_TYPE, leasingType);
		},
		setLeasingTitle({ commit }, leasingTitle) {
			commit(SET_LEASING_TITLE, leasingTitle);
		},
		setCalculatorType({ commit }, calculatorType) {
			commit(SET_CALCULATOR_TYPE, calculatorType);
		},
		setRnote({ commit }, rnote) {
			commit(SET_RNOTE, rnote);
		},
		setRapplId({ commit }, rapplId) {
			commit(SET_RAPPL_ID, rapplId);
		},
		setEmail({ commit }, email) {
			commit(SET_EMAIL, email);
		},
		setDebtorType({ commit }, debtorType) {
			commit(SET_DEBTOR_TYPE, debtorType);
		},
		setFinanceType({ commit }, financeType) {
			commit(SET_FINANCE_TYPE, financeType);
		}
	},
	mutations: {
		[SET_REST_AMOUNT_TEXT](state, restAmountText) {
			state.restAmountText = restAmountText;
		},
		[SET_INSTALMENT](state, instalment) {
			state.instalment = instalment;
		},
		[SET_PRECALC_INSTALMENT](state, precalcInstalment) {
			state.precalcInstalment = precalcInstalment;
		},
		[SET_FINANCED_PRICE](state, financedPrice) {
			state.financedPrice = financedPrice;
		},
		[SET_INTEREST_TYPE](state, interestType) {
			state.interestType = interestType;
		},
		[SET_DURATION_TEXT](state, durationText) {
			state.durationText = durationText;
		},
		[SET_LEASING_TYPE](state, leasingType) {
			state.leasingType = leasingType;
		},
		[SET_LEASING_TITLE](state, leasingTitle) {
			state.leasingTitle = leasingTitle;
		},
		[SET_CALCULATOR_TYPE](state, calculatorType) {
			state.calculatorType = calculatorType;
		},
		[SET_RNOTE](state, rnote) {
			state.rnote = rnote;
		},
		[SET_RAPPL_ID](state, rapplId) {
			state.rapplId = rapplId;
		},
		[SET_EMAIL](state, email) {
			state.email = email;
		},
		[SET_DEBTOR_TYPE](state, debtorType) {
			state.debtorType = debtorType;
		},
		[SET_FINANCE_TYPE](state, financeType) {
			state.financeType = financeType;
		},
		[SET_DURATION](state, duration) {
			state.duration = duration;
		}
	}
};
