import { withParams } from 'vuelidate';
import XRegExp from 'xregexp';
/*
	Ha tombot kap akkor legalabb az egyiknek meg kell felelnie
*/
export default (pattern) => withParams({ type: 'pattern' }, value => {
	if (typeof value === 'undefined' || value === null || value === '') {
		return true;
	}
	let match = false;
	if (Array.isArray(pattern)) {
		for (let i = 0; i < pattern.length; i++) {
			let reg = new XRegExp(pattern[i]);
			match = match || reg.test(value);
		}
	} else {
		let reg = new XRegExp(pattern);
		match = reg.test(value);
	}
	return match;
});
