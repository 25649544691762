<template lang="pug">
	.calculation-email-feedback.success
		VueFeedback(type="success")
			template(slot="feedback-title") Köszönjük érdeklődését!
			template(slot="feedback-content")
				slot(:email="email")
			VueButton(slot="feedback-action", @click="$closeModal('email-calculation-modal')") Bezárom
</template>

<script>
import VueFeedback from '@ui-common/vue-feedback';
import VueButton from '@ui-common/vue-button';
export default {
	props: {
		email: {
			required: true
		}
	},
	components: {
		VueFeedback,
		VueButton
	}
};
</script>

<style lang="scss">
@import '@ui-common/sf-style-utils/lib/helpers/_colors.scss';
@import '@ui-common/sf-style-utils/lib/helpers/_queries.scss';

.calculation-email-feedback.success .sf-feedback__content {
	margin-bottom: 32px;

	@include media('>=tablet') {
		margin-bottom: 48px;
	}
}
</style>
