<template lang="pug">
.calculation-email-result
	.calculation-email-result__title
		p.sf-heading-2 Kalkuláció eredménye
		p.sf-text {{ calculationEmailtext }}

	.calculation-email-result__financed-price
		p.sf-text Finanszírozott összeg
		p.sf-text.sf--bold {{ financedPrice | currency('HUF') }} Ft

	.calculation-email-result__loan-duration
		p.sf-text Futamidő
		p.sf-text.sf--bold {{ durationText }}

	.calculation-email-result__montly-price
		p.sf-text {{ leasingTitle }}
		p.sf-heading-1 {{ instalment | currency('HUF') }} Ft
</template>
<script>
import VueButton from '@ui-common/vue-button';
import { mapState, mapGetters } from 'vuex';
import { DEBTOR_TYPES } from '../vehicle-leasing-calculator/constants';
export default {
	components: {
		VueButton
	},
	computed: {
		...mapState('calculation-email', ['instalment', 'precalcInstalment', 'financedPrice', 'restAmountText', 'durationText', 'debtorType', 'leasingTitle']),
		...mapGetters('calculation-email', ['calculationEmailtext']),
		isPersonal() {
			return this.debtorType == DEBTOR_TYPES.PERSONAL;
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@ui-common/sf-style-utils/lib/helpers/_colors.scss';
@import '@ui-common/sf-style-utils/lib/helpers/_queries.scss';

.calculation-email-result {
	padding: 0 24px;
}

.calculation-email-result > div:last-child {
	margin-bottom: 0;
}

.calculation-email-result .sf-text {
	margin: 0;
}

.calculation-email-result__title {
	margin-bottom: 32px;
}

.calculation-email-result__title .sf-heading-2 {
	margin-bottom: 32px;
}

.calculation-email-result__financed-price,
.calculation-email-result__loan-duration,
.calculation-email-result__montly-price {
	margin-bottom: 32px;
}

.calculation-email-result__montly-price .sf-heading-1 {
	margin-bottom: 0;
}
</style>
