import { withParams } from 'vuelidate';
import {EmailValidator} from 'commons-validator-js';
const emailValidator = new EmailValidator();
export default withParams({ type: 'email' }, value => {
	if (typeof value === 'undefined' || value === null || value === '') {
		return true;
	}
	if (emailValidator.isValid(value)) {
		return true;
	}
	return false;
});