import VueSearch from '@ui-common/vue-search';
import debounce from 'lodash.debounce';
import searchApi from '../../../api/search-api';

const SEARCH_MIN_CHARS = 3;

export default {
	data: () => ({
		items: [],
		model: {
			token: null
		}
	}),
	props: {
		searchPageUrl: {
			type: String,
			required: true
		},
		type: {
			type: String,
			required: false,
			default: 'FRAME'
		}
	},
	components: {
		VueSearch
	},
	methods: {
		update: debounce(function() {
			if (this.model.token.length >= SEARCH_MIN_CHARS) {
				searchApi.getSuggestions(this.model.token).then(response => {
					if (this.model.token.length >= SEARCH_MIN_CHARS) {
						this.items = response.data.map(item => ({ label: item }));
					} else {
						this.reset();
					}
				});
			} else {
				this.reset();
			}
		}, 200),
		reset() {
			this.items = [];
		},
		sendGtm() {
			window.dataLayer.push({
				event: 'siteSearch',
				siteSearchValue: this.model.token
			});
		}
	},
	mounted() {
		this.$refs.search.$refs.form.addEventListener('submit', this.sendGtm);
	}
};
