import Vue from 'vue';
import Vuelidate from 'vuelidate';
import currencyFilter from '../common/js/currency-filter/currency-filter';
import VueScrollTo from 'vue-scrollto';
import mainMenu from '../frame/header-menu/header-menu.js';
import VueModal from '@ui-common/vue-modal';
import VueNotification from '@ui-common/vue-notification';
import VueTooltip from '@ui-common/vue-tooltip';
import VueLiveAnnouncer from '@ui-common/vue-live-announcer';
import VueTableOfContent from '@ui-common/vue-table-of-content';
import VueTab from '@ui-common/vue-tab';
import VueGallery from '@ui-common/vue-gallery';
import { VueAccordion, VueAccordionItem } from '@ui-common/vue-accordion';
import PortalVue from 'portal-vue';
import 'wicg-inert';
import 'focus-visible';
import MerkantilNotification from '../common/components/notification/notification';
import { getSiblings } from '@ui-common/sf-js-utils';
import { addI18N, i18n } from './i18n';
import SearchWrapper from '../common/components/search-wrapper/search-wrapper';
import moav from '../modules/MOAV/moav.js';
import store from '../store';
import VueGtm from 'vue-gtm';
addI18N();
Vue.use(PortalVue);
Vue.use(Vuelidate);
Vue.use(VueModal);
Vue.use(VueScrollTo);
Vue.use(VueGtm, {});
Vue.use(VueLiveAnnouncer);
// disableSpaceScroll();
Vue.config.productionTip = false;
Vue.filter('currency', currencyFilter);

Vue.component('main-menu', mainMenu);
Vue.component('vue-notification', VueNotification);
Vue.component('vue-tooltip', VueTooltip);
Vue.component('vue-table-of-content', VueTableOfContent);
Vue.component('merkantil-notification', MerkantilNotification);
Vue.component('vue-tab', VueTab);
Vue.component('vue-accordion', VueAccordion);
Vue.component('vue-accordion-item', VueAccordionItem);
Vue.component('search-wrapper', SearchWrapper);
Vue.component('vue-gallery', VueGallery);
Vue.component('moav', moav);

// if (process.env.NODE_ENV == 'development') {
// 	import('no-mouse-days')
// }

export default {
	i18n,
	store,
	mounted() {
		this.$modalEventBus.$on('toggle-inert', isInert => {
			const modalContainer = document.querySelector('#modal-content');
			const siblings = getSiblings(modalContainer);
			Object.keys(siblings).map(key => {
				return (siblings[key].inert = isInert);
			});
		});
	}
};
