import { render, staticRenderFns } from "./calculation-email-error.vue?vue&type=template&id=40c9930c&lang=pug"
import script from "./calculation-email-error.vue?vue&type=script&lang=js"
export * from "./calculation-email-error.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports