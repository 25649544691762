const findErrorComponents = component => {
	let errorComponents = [];
	component.$children.forEach(child => {
		if (child.hasError) {
			errorComponents.push(child);
		}
		if (child.$children && child.$children.length) {
			errorComponents = [...errorComponents, ...findErrorComponents(child)];
		}
	});
	return errorComponents;
};
export const findFirstErrorComponent = component => {
	const errorComponents = findErrorComponents(component);
	const sortedErrorComponents = errorComponents.sort((a, b) => {
		return a.$el.getBoundingClientRect().top - b.$el.getBoundingClientRect().top;
	});
	return sortedErrorComponents[0];
};

export const jumpToFirstError = {
	methods: {
		jumpToFirstError() {
			this.$nextTick(() => {
				const firstErrorComponent = findFirstErrorComponent(this);
				if (firstErrorComponent) {
					const firstErrorInput = firstErrorComponent.$el.parentNode.querySelector('input, button');
					if (firstErrorInput) {
						firstErrorInput.focus();
					}
				}
			});
		}
	}
};
