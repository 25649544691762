import axios from 'axios';
export default {
	getSuggestions(term) {
		return axios.get('/merkantil/search/suggest', {
			params: {
				term
			}
		});
	},
	search(term, page = 0) {
		return axios.get('/merkantil/search', {
			params: {
				term,
				page
			}
		});
	}
};
