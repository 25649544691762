export const RESULTSTATES = {
	FORM: 'FORM',
	SUCCESS: 'SUCCESS',
	ERROR: 'ERROR',
	CALCULATION_ERROR: 'CALCULATION_ERROR'
};
export const CALCULATION_RETURN_CODES = {
	SUCCESS: '0',
	ERROR: '1',
	TECHNICAL_ERROR: '999'
};
export const FORM_DEFAULTS = {
	DEBTOR_TYPE: '1',
	VEHICLE_DESIGNATION: 'C'
};
export const QUERY_PARAMS = {
	DEBTOR_TYPE: 'vLeasingDebtor',
	VEHICLE_DESIGNATION: 'vLeasingVehicleDesignation'
};
export const DEBTOR_TYPES = {
	PERSONAL: '1',
	ENTERPRISE: '2',
	SELF_EMPLOYED: '3'
};
export const VEHICLE_DESIGNATION = {
	CAR: 'C',
	LORRY: 'L',
	BIKE: 'M',
	TRUCK: 'A'
};
export const LEASING_TYPE = {
	CLOSED: '2',
	OPEN: '3'
};
export const VAT_RATE = 27;
export const MIN_OWN_CONTRIBUTION_RATE = 0.2;
export const INSURANCE_TYPE = {
	INCLUDED: '7'
};
export const VEHICLE_AGE_LIMIT = {
	PERSONAL: 16,
	ENTERPRISE: 14
};
export const VEHICLE_AGE_END_OF_FINANCING_LIMIT = {
	PERSONAL: 18,
	ENTERPRISE: 15
};
export const MIN_DEBTOR_AGE = 18;
export const CALCULATOR_TYPES = {
	CAR_LEASING: 'gépjármű lízing'
};
export const INTEREST_TYPES = {
	FIX: 'fix',
	VARIABLE: 'változó'
};

export const MIN_FINANCED_PRICE = {
	NEW: 400000,
	OLD: 200000
};
export const MAX_FINANCED_PRICE = {
	NEW: 80000000,
	OLD: 80000000
};
export const INSURANCE_OWN_CONTRIBUTION_DEFAULT = '10050';
export const INCLUDED_INSURANCE_FIELDS = [
	'manufacturer',
	'performance',
	'insuranceOwnContribution',
	'vehicleManufacturerToDesignation',
	'vehiclePerformance',
	'debtorAge',
	'zipCode'
];
export const OPEN_LEASING_FIELDS = ['netSellPrice', 'vat', 'registrationTax', 'restAmountPercent'];
export const REST_AMOUNT_MIN_MAX = {
	LT_TWELVE_MILLION: {
		12: { MIN: 0.3, MAX: 0.5 },
		24: { MIN: 0.25, MAX: 0.4 },
		36: { MIN: 0.2, MAX: 0.35 },
		48: { MIN: 0.15, MAX: 0.3 },
		60: { MIN: 0.1, MAX: 0.25 },
		72: { MIN: 0.1, MAX: 0.15 },
		84: { MIN: 0.05, MAX: 0.05 },
		96: { MIN: 0.05, MAX: 0.05 }
	},
	GE_TWELVE_MILLION: {
		12: { MIN: 0.3, MAX: 0.4 },
		24: { MIN: 0.2, MAX: 0.3 },
		36: { MIN: 0.15, MAX: 0.3 },
		48: { MIN: 0.1, MAX: 0.25 },
		60: { MIN: 0.1, MAX: 0.2 },
		72: { MIN: 0.05, MAX: 0.1 },
		84: { MIN: 0.05, MAX: 0.05 },
		96: { MIN: 0.05, MAX: 0.05 }
	}
};
export const VEHICLE_SOURCE = {
	HOME: '1',
	IMPORT: '2'
};
