<template lang="pug">
	.calculation-email
		h3.sf-heading-2.page-subtitle(tabindex="-1")
			slot(name="title")
		CalculationEmailForm(v-show="resultState == RESULTSTATES.FORM", ref="form", :email-form-type="emailFormType", :captcha-img="captcha.htmlFragment", :type="type", :captcha-error="captchaError", @captcha-keyup="resetCaptchaError", @submit="send")
		CalculationEmailSuccess(v-if="resultState == RESULTSTATES.SUCCESS", :email="email")
			template(v-slot="{ email }")
				slot(:email="email")
		CalculationEmailError(v-if="resultState == RESULTSTATES.ERROR", :public-error-message="publicErrorMessage")
</template>

<script>
import CalculationEmailForm from './calculation-email-form/calculation-email-form';
import CalculationEmailSuccess from './calculation-email-success/calculation-email-success';
import CalculationEmailError from './calculation-email-error/calculation-email-error';
import calculationEmailApiFactory from '../../api/calculation-email-api';
import VueFormNotification from '@ui-common/vue-form-notification';
import { mapState } from 'vuex';
import { TYPES } from './constants';
const RESULTSTATES = {
	FORM: 'FORM',
	SUCCESS: 'SUCCESS',
	ERROR: 'ERROR'
};
export default {
	data: () => ({
		calculationEmailApi: null,
		email: null,
		captcha: {
			captchaKey: null,
			htmlFragment: null
		},
		captchaError: false,
		resultState: RESULTSTATES.FORM,
		result: null,
		publicErrorMessage: null
	}),
	props: {
		type: {
			type: String,
			required: false,
			default: TYPES.PRODUCTION_TOOL,
			validator(val) {
				return Object.values(TYPES).includes(val);
			}
		},
		emailFormType: {
			required: false,
			type: String
		}
	},
	components: {
		CalculationEmailForm,
		CalculationEmailSuccess,
		CalculationEmailError,
		VueFormNotification
	},
	watch: {
		resultState(val) {
			if (val == RESULTSTATES.SUCCESS || val == RESULTSTATES.ERROR) {
				// this.$changeModalWidth('email-calculation-modal', 'narrow');
				this.$hideModalHeader('email-calculation-modal');
				this.$emit('change-calculation-visible', false);
			} else {
				// this.$changeModalWidth('email-calculation-modal', 'wide');
				this.$showModalHeader('email-calculation-modal');
				this.$emit('change-calculation-visible', true);
			}
		}
	},
	computed: {
		...mapState('calculation-email', ['rapplId', 'rnote', 'financeType']),
		RESULTSTATES() {
			return RESULTSTATES;
		}
	},
	methods: {
		resetCalculationEmail() {
			this.resultState = RESULTSTATES.FORM;
			this.publicErrorMessage = null;
			this.$nextTick(() => {
				this.resetCaptchaError();
				this.init();
				if (this?.$refs?.form) {
					this.$refs.form.resetForm();
				}
			});
		},
		resetCaptchaError() {
			this.captchaError = false;
		},
		async send(data) {
			try {
				this.resetCaptchaError();
				this.email = data.email;
				this.$store.dispatch('calculation-email/setEmail', this.email);
				const dataToSend = Object.assign({}, data, {
					captchaKey: this.captcha.captchaKey,
					url: window.location.href,
					rnote: this.rnote,
					rapplId: this.rapplId,
					financeType: this.financeType
				});
				await this.calculationEmailApi.submit(dataToSend);
				this.resultState = RESULTSTATES.SUCCESS;
			} catch (err) {
				if (err.response.status == 400) {
					if (err.response.data && err.response.data.error && err.response.data.error == 'captchaText') {
						this.captchaError = true;
						this.$refs.form.resetCaptcha();
						this.generateCaptcha();
					} else if (err.response.data && err.response.data.publicErrorMessage) {
						this.resultState = RESULTSTATES.ERROR;
						this.publicErrorMessage = err.response.data.publicErrorMessage;
					}
				} else {
					this.resultState = RESULTSTATES.ERROR;
				}
			}
		},
		async generateCaptcha() {
			try {
				const response = await this.calculationEmailApi.generateCaptcha();
				this.captcha = response.data;
			} catch (err) {
				this.resultState = RESULTSTATES.ERROR;
			}
		},
		async init() {
			try {
				const response = await this.calculationEmailApi.init();
				this.captcha = response.data;
			} catch (err) {
				this.resultState = RESULTSTATES.ERROR;
			}
		}
	},
	created() {
		this.calculationEmailApi = calculationEmailApiFactory(this.type);
		this.init();
		this.$modalEventBus.$on('modal-closed', modalId => {
			if (modalId == 'email-calculation-modal') {
				this.resetCalculationEmail();
			}
		});
	}
};
</script>

<style lang="scss" scoped>
@import '@ui-common/sf-style-utils/lib/helpers/_queries.scss';

.calculation-email ::v-deep .form-row .form-col:first-of-type {
	@include media('>=tablet') {
		max-width: 160px;
	}
}

.calculation-email .sf-captcha__wrapper input {
	@include media('>=desktop') {
		width: 104px;
	}
}

.calculation-email .sf-captcha__wrapper img {
	@include media('>=desktop') {
		margin-left: 8px;
	}
}

.calculation-email-feedback {
	padding-bottom: 32px;
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 32px;

	@include media('>=tablet') {
		padding-bottom: 48px;
		padding-top: 48px;
	}

	@include media('>=desktop') {
		padding-bottom: 64px;
		padding-top: 64px;
	}
}

.calculation-email > h3 {
	padding-right: 40px;
}
</style>
