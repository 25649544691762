<template lang="pug">
.merkantil-notification(:class="{'not-visited': isVisible}")
	slot(:is-mobile="isMobile", :visited="visited", :set-visited-state="setVisitedState")
</template>
<script>
import { isMobile } from '@ui-common/sf-js-utils';
const NOTIFICATION_COOKIE = 'merkantil-notification-visited';
export default {
	data: () => ({
		visited: false,
		isVisible: true
	}),
	mixins: [isMobile],
	methods: {
		setVisitedState() {
			sessionStorage.setItem(NOTIFICATION_COOKIE, true);
			this.isVisible = false;
		},
		getVisitedState() {
			return sessionStorage.getItem(NOTIFICATION_COOKIE);
		}
	},
	created() {
		this.visited = this.getVisitedState();
		if (this.visited) {
			this.isVisible = false;
		} else {
			this.isVisible = true;
		}
	}
};
</script>
<style lang="scss" scoped>
	.merkantil-notification.not-visited .sf-notification {
		position: relative;
	}

	.sf-notification-slide-leave-active {
		animation: none;
	}
</style>