<template lang="pug">
	.erf-form
		form(@submit.prevent="send")
			.form-row(v-if="isProductionTool", :class="{'has-error': $v.model.enterpriseName.$dirty && $v.model.enterpriseName.$invalid}")
				.form-col
					label#erf-company-enterprisename-label(for="erf-company-name-input") Vállalkozás neve
				.form-col
					VueInput(v-model="model.enterpriseName", id="erf-company-name-input", :max-length="50", :has-error="$v.model.enterpriseName.$dirty && $v.model.enterpriseName.$invalid", @blur="$v.model.enterpriseName.$touch()", aria-describedby="erf-company-name-error")
					VueErrorMessage(id="erf-company-name-error", v-if="$v.model.enterpriseName.$dirty && !$v.model.enterpriseName.required", position="bottom") Kérjük, adja meg a vállalkozás nevét!
			.form-row(:class="{'has-error': $v.model.name.$dirty && $v.model.name.$invalid}")
				.form-col
					label#erf-company-name-label(for="erf-contact-name-input") {{isProductionTool? 'Kapcsolattartó neve' : 'Neve'}}
				.form-col
					VueInput(v-model="model.name", id="erf-contact-name-input", :max-length="50", :has-error="$v.model.name.$dirty && $v.model.name.$invalid", @blur="$v.model.name.$touch()", aria-describedby="erf-company-name-error")
					VueErrorMessage(id="erf-company-name-error", v-if="$v.model.name.$dirty && !$v.model.name.required", position="bottom") Kérjük, adja meg a {{isProductionTool? 'kapcsolattartó' : ''}} nevét
			.form-row(:class="{'has-error': $v.model.email.$dirty && $v.model.email.$invalid}")
				.form-col
					label#erf-contact-email-label(for="erf-contact-email-input") E-mail címe
				.form-col
					VueInput(v-model="model.email", id="erf-contact-email-input", :max-width="352", :max-length="50", :has-error="$v.model.email.$dirty && $v.model.email.$invalid", @blur="$v.model.email.$touch()", aria-describedby="erf-contact-email-error")
					VueErrorMessage(id="erf-contact-email-error", v-if="$v.model.email.$dirty && !$v.model.email.required", position="bottom") Kérjük, adja meg e-mail címét!
					VueErrorMessage(id="erf-contact-email-error", v-if="$v.model.email.$dirty && !$v.model.email.email", position="bottom") Kérjük, ellenőrizze az e-mail címet!
			.form-row(:class="{'has-error': $v.model.mobilePhone.$dirty && $v.model.mobilePhone.$invalid}")
				.form-col
					label#erf-contact-mobile-phone-label(for="erf-contact-mobile-phone-input") Telefonszáma
				.form-col
					VueMobilephoneInput(id="erf-contact-mobile-phone-input", v-model="model.mobilePhone", :max-width="180" :max-width-contact-name-input="176", prefix="+36", addon="pl.: 20 123 4567", @blur="$v.model.mobilePhone.$touch()", :has-error="$v.model.mobilePhone.$dirty && $v.model.mobilePhone.$invalid", aria-describedby="erf-contact-mobile-phone-error")
					VueErrorMessage(id="erf-contact-mobile-phone-error", v-if="$v.model.mobilePhone.$dirty && !$v.model.mobilePhone.required", position="bottom") Kérjük, adja meg a telefonszámát!
					VueErrorMessage(id="erf-contact-mobile-phone-error", v-if="$v.model.mobilePhone.$dirty && !$v.model.mobilePhone.pattern", position="bottom") Kérjük ellenőrizze a telefonszámát!
			.form-row(v-if="captchaImg", :class="{'has-error': $v.model.captchaText.$dirty && $v.model.captchaText.$invalid || captchaError}")
				.form-col
					label#erf-captcha-label(for="erf-captcha-input") Írja be a képen látható kódot
				.form-col
					VueCaptcha(id="erf-captcha-input", :img="'data:image/png;base64,' + captchaImg", v-model="model.captchaText", :max-width="104", @keyup="$emit('captcha-keyup')", @blur="$v.model.captchaText.$touch()", :has-error="$v.model.captchaText.$dirty && $v.model.captchaText.$invalid || captchaError", aria-describedby="erf-captcha-error")
					VueErrorMessage(id="erf-captcha-error" v-if="$v.model.captchaText.$dirty && !$v.model.captchaText.required", position="bottom") Kérjük, adja meg a képen látható kódot!
					VueErrorMessage(id="erf-captcha-error" v-if="captchaError" position="bottom") Kérjük, ellenőrizze a képen látható kódot!
			hr
			.form-row(:class="{'has-error': $v.model.acceptPrivacyStatement.$dirty && $v.model.acceptPrivacyStatement.$invalid}")
					VueCheckbox(id="erf-accept-privacy-statement-checkbox", v-model="model.acceptPrivacyStatement", @blur="$v.model.acceptPrivacyStatement.$touch()", :has-error="$v.model.acceptPrivacyStatement.$dirty && $v.model.acceptPrivacyStatement.$invalid", aria-describedby="erf-accept-privacy-statement-error")
						slot
							template Nyilatkozom, hogy a Merkantil Bank Zrt. #[a.link(href="/static/merkantil/sw/file/MERKANTIL_BANK_GDPR_AAT.pdf" target="_blank", rel="noopener noreferrer") Általános adatvédelmi tájékoztatóját] és a #[a.link(href="/static/merkantil/sw/file/BANK_Finanszirozas_Adatkezelesi_tajekoztato.pdf" target="_blank", rel="noopener noreferrer") Finanszírozási szerződésekre vonatkozó adatkezelési tájékoztatót] elolvastam, tudomásul vettem és hozzájárulok adataim kezeléséhez az ajánlatadás és a szerződés előkészítése során végzett kapcsolattartás érdekében.
					VueErrorMessage(id="erf-accept-privacy-statement-error" v-if="$v.model.acceptPrivacyStatement.$dirty && !$v.model.acceptPrivacyStatement.checked", position="bottom") Kérjük olvassa el és fogadja el az adatvédelmi tájékoztatókat!
			VueButton(ref="button") Kérem a kalkulációt
</template>

<script>
import VueButton from '@ui-common/vue-button';
import VueCaptcha from '@ui-common/vue-captcha';
import VueCheckbox from '@ui-common/vue-checkbox';
import VueErrorMessage from '@ui-common/vue-error-message';
import VueFormInfo from '@ui-common/vue-form-info';
import VueInput from '@ui-common/vue-input';
import VueMobilephoneInput from '@ui-common/vue-mobilephone-input';
import { required } from 'vuelidate/lib/validators';
import pattern from '../../../common/js/validators/pattern';
import email from '../../../common/js/validators/email';
import { jumpToFirstError } from '../../../common/js/find-first-error-component/find-first-error-component';
import { MOBILE_PHONE_REGEXP } from '../../../common/js/constants/constants';
import { TYPES } from '../constants';
const DEFAULT_MODEL = {
	enterpriseName: null,
	name: null,
	mobilePhone: null,
	email: null,
	captchaText: null,
	acceptPrivacyStatement: null
};
export default {
	mixins: [jumpToFirstError],
	data: () => ({
		model: JSON.parse(JSON.stringify(DEFAULT_MODEL))
	}),
	props: {
		captchaImg: {
			required: true
		},
		captchaError: {
			required: true
		},
		type: {
			type: String,
			required: false
		},
		emailFormType: {
			required: false,
			type: String
		}
	},
	validations() {
		return {
			model: {
				enterpriseName: this.isProductionTool
					? {
							required
					  }
					: {},
				name: {
					required
				},
				email: {
					required,
					email
				},
				mobilePhone: {
					required,
					pattern: pattern(MOBILE_PHONE_REGEXP)
				},
				captchaText: this.captchaImg
					? {
							required
					  }
					: {},
				acceptPrivacyStatement: {
					checked: value => value === true
				}
			}
		};
	},
	components: {
		VueButton,
		VueCaptcha,
		VueCheckbox,
		VueErrorMessage,
		VueFormInfo,
		VueInput,
		VueMobilephoneInput
	},
	watch: {
		captchaError(val) {
			if (val) {
				this.$nextTick(() => {
					this.jumpToFirstError();
				});
			}
		}
	},
	computed: {
		isProductionTool() {
			return this.type == TYPES.PRODUCTION_TOOL;
		}
	},
	methods: {
		focusButton() {
			this.$refs.button.$refs.button.focus();
		},
		send() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				this.jumpToFirstError();
			} else {
				this.focusButton();
				this.$emit('submit', this.model);
			}
		},
		async getSettlements(zipCode) {
			this.$emit('zip-change', zipCode);
		},
		resetForm() {
			this.$v.model.$reset();
			this.model = JSON.parse(JSON.stringify(DEFAULT_MODEL));
			this.setEmailFormType();
		},
		resetCaptcha() {
			this.model.captchaText = null;
			this.$v.model.captchaText.$reset();
		},
		setEmailFormType() {
			if (this.emailFormType) {
				this.model.emailFormType = this.emailFormType;
			}
		}
	},
	created() {
		this.setEmailFormType();
	}
};
</script>

<style lang="scss">
@import '@ui-common/sf-style-utils/lib/helpers/_colors.scss';
@import '@ui-common/sf-style-utils/lib/helpers/_queries.scss';
/*  @import '../../../layouts/ANYIT/form-containers.scss'; */

.erf-form .form-row {
	padding: 0;
}

.erf-form .form-row .sf-form-info {
	margin: 0;
}

.erf-form .form-row .sf-checkbox {
	margin-bottom: 12px;
}
</style>

<style lang="scss" scoped>
.erf-form ::v-deep .sf-captcha__wrapper img {
	max-width: 94px;
}
</style>
