<template lang="pug">
	.calculation-email-feedback.error
		VueFeedback(type="error")
			template(slot="feedback-title") Hoppá!
			template(slot="feedback-content") 
				p Sajnos jelenleg nem tud ajánlatot kérni a mi hibánkból, de már dolgozunk rajta. Kérjük nézzen vissza később!
				p(v-if="publicErrorMessage") {{publicErrorMessage}}
			VueButton(slot="feedback-action", @click="$closeModal('email-calculation-modal')") Bezárom
</template>

<script>
import VueFeedback from '@ui-common/vue-feedback';
import VueButton from '@ui-common/vue-button';
export default {
	props: {
		publicErrorMessage: {
			type: String
		}
	},
	components: {
		VueFeedback,
		VueButton
	}
};
</script>
