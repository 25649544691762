import VueI18n from 'vue-i18n';
import Vue from 'vue';
let i18n;
const addI18N = () => {
	Vue.use(VueI18n);
	i18n = new VueI18n({
		locale: 'hu'
	});
};
export { i18n, addI18N };
