import { render, staticRenderFns } from "./search-wrapper.pug?vue&type=template&id=79865069&lang=pug&external"
import script from "./search-wrapper.js?vue&type=script&lang=js&external"
export * from "./search-wrapper.js?vue&type=script&lang=js&external"
import style0 from "@ui-common/vue-search/dist/vue-search.css?vue&type=style&index=0&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports