import axios from 'axios';
import { API_URIS } from '../applications/calculation-email/constants';
export default type => {
	const apiUrlPrefix = `/merkantil/${API_URIS[type]}`;
	return {
		init() {
			return axios.get(`${apiUrlPrefix}/init`);
		},
		generateCaptcha() {
			return axios.get('/merkantil/captcha/generate', {
				params: {
					captchaId: 'MERKANTIL_PT_EMAIL'
				}
			});
		},
		submit(data) {
			return axios.post(apiUrlPrefix, data);
		}
	};
};
