import { i18n } from '../../../layouts/i18n';
export default function(value, currency) {

	let fixed = currency == 'HUF' ? 0 : 2;
	value = parseFloat(value);
	if (!Number.isNaN(value)) {
		return new Intl.NumberFormat(i18n.locale, { minimumFractionDigits: fixed, maximumFractionDigits: fixed }).format(value);
	} else {
		return '';
	}
}
