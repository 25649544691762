import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
Vuex.Store.prototype.hasModule = function(aPath) {
	let m = this._modules.root;
	return aPath.every(p => {
		m = m._children[p];
		return m;
	});
};

export default new Vuex.Store({
	strict: process.env.NODE_ENV !== 'production'
});
